<template>
  <div class="apply-wrap">
    <div class="apply-top">
      <preprocessing-apply-top
        v-bind="mainData"
        :loading="loading"
        :preProcHeadVersion="pageBottom.preProcHeadVersion"
        :preProcLatestVersion="pageBottom.preProcLatestVersion"
        :autoPreprocessingApplied="autoPreprocessingApplied"
        :errorPopup="mainData.errorPopup"
        @open-auto-preprocess="$emit('open-auto-preprocess')"
        @open-save-dataset="$emit('open-save-dataset', $event)"
        @open-stop-move="$emit('open-stop-move', $event)"
      />
    </div>
    <div class="apply-main">
      <div class="apply-body">
        <preprocessing-apply-body
          v-bind="pageBody"
          :visibleContents="mainData.visibleContents"
          :tabs="mainData.tabs"
          :activeTab="mainData.activeTab"
          :mainData="mainData"
          @show-detail="$emit('show-detail', $event)"
          @show-statistics-detail="$emit('show-statistics-detail', $event)"
          @change-tab="$emit('change-tab', $event)"
          @get-statistics="$emit('get-statistics')"
          @get-outlier="$emit('get-outlier')"
          @do-delete-rows="$emit('do-delete-rows', $event)"
        />
      </div>
      <div class="apply-side">
        <preprocessing-apply-side
          v-bind="pageBody"
          :preprocessingId="mainData.preprocessingId"
          :datasetId="mainData.datasetId"
          :errorPopup="mainData.errorPopup"
          :isUsed="mainData.isUsed"
          @apply-preprocessing="$emit('apply-preprocessing')"
          @select-preprocessing="$emit('select-preprocessing', $event)"
          @select-dataset="$emit('select-dataset', $event)"
          @close-error-popup="$emit('close-error-popup')"
          @go-detail="$emit('go-detail')"
        />
      </div>
      <div class="apply-bottom">
        <preprocessing-apply-bottom
          v-bind="pageBottom"
          :loading="loading"
          :checked="checked"
          :appliedChart="appliedChart"
          @open-save-preprocessing="$emit('open-save-preprocessing', $event)"
          @reset-preprocessing="$emit('reset-preprocessing')"
          @back-version="$emit('back-version')"
          @next-version="$emit('next-version')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import preprocessingApplyTop from './preprocessing-apply-top'
import preprocessingApplyBody from './preprocessing-apply-body'
import preprocessingApplySide from './preprocessing-apply-side'
import preprocessingApplyBottom from './preprocessing-apply-bottom'

export default {
  components: {
    preprocessingApplyTop,
    preprocessingApplyBody,
    preprocessingApplySide,
    preprocessingApplyBottom
  },
  data() {
    return {
      checked: []
    }
  },
  props: {
    pageBody: Object,
    pageBottom: Object,
    loading: Boolean,
    loadingInit: Boolean,
    appliedChart: Object,
    mainData: Object,
    autoPreprocessingApplied: Boolean
  }
}
</script>

<style lang="scss" scoped>
.apply {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: calc(
      100vh - #{adjustVH($headerTabHeight)}
    ); // ヘッダーサイズ分だけ小さくしている
  }
  &-main {
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'body side'
      'bottom bottom';
    grid-template-columns: calc(100% - #{adjustVW(384)}) adjustVW(384);
    grid-template-rows: calc(100% - #{adjustVH(208)}) adjustVH(208);
    width: 100%;
    padding-bottom: $space-medium;
  }
  &-top {
    width: 100%;
    padding: 0 $space-medium;
  }
  &-body {
    grid-area: body;
    overflow: hidden;
  }
  &-side {
    grid-area: side;
    width: calc(100% - #{$space-medium});
    padding-bottom: $space-medium;
  }
  &-bottom {
    flex-shrink: 0;
    width: 100%;
    padding: 0 $space-medium;
    grid-area: bottom;
  }
}
</style>
