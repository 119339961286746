<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFound || loading || loadingInit"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <!-- 小さいポップアップだとくるくるが被るので特別対応 -->
          <div v-if="(loading || loadingInit) && disableClick" />
          <loading v-else-if="loading || loadingInit" />
          <page-not-found v-else-if="notFound" />
          <preprocessing-apply-main
            v-else
            v-bind="body"
            :mainData="mainData"
            :loading="loading"
            :loadingInit="loadingInit"
            :autoPreprocessingApplied="autoPreprocessingApplied"
            @change-tab="$emit('change-tab', $event)"
            @open-auto-preprocess="$emit('open-auto-preprocess')"
            @open-save-dataset="$emit('open-save-dataset', $event)"
            @open-save-preprocessing="$emit('open-save-preprocessing', $event)"
            @reset-preprocessing="$emit('reset-preprocessing')"
            @back-version="$emit('back-version')"
            @next-version="$emit('next-version')"
            @apply-preprocessing="$emit('apply-preprocessing')"
            @select-preprocessing="$emit('select-preprocessing', $event)"
            @select-dataset="$emit('select-dataset', $event)"
            @close-error-popup="$emit('close-error-popup')"
            @open-stop-move="$emit('open-stop-move', $event)"
            @show-detail="$emit('show-detail', $event)"
            @show-statistics-detail="$emit('show-statistics-detail', $event)"
            @go-detail="$emit('go-detail')"
            @get-statistics="$emit('get-statistics')"
            @get-outlier="$emit('get-outlier')"
            @do-delete-rows="$emit('do-delete-rows', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="preprocessing-apply-popup">
      <preprocessing-apply-popup
        v-bind="mainData.popup"
        :autoPreprocessingApplied="autoPreprocessingApplied"
        :dataset="body.pageBody.dataset"
        :appliedChart="body.appliedChart"
        :datasetName="datasetName"
        :datasetSizeWarning="datasetSizeWarning"
        :preProcHeadVersion="body.pageBottom.preProcHeadVersion"
        :disableClick="disableClick"
        :preprocessingList="body.pageBody.preprocessingList"
        :isLargeCSV="isLargeCSV"
        @apply-auto-preprocess="$emit('apply-auto-preprocess')"
        @close-modal="$emit('close-modal', $event)"
        @go-dataset-list-page="$emit('go-dataset-list-page')"
        @input-create-form="$emit('input-create-form', $event)"
        @open-save-close="$emit('open-save-close')"
        @reset-preprocessing-confirm="$emit('reset-preprocessing-confirm')"
        @save-dataset="$emit('save-dataset', $event)"
        @save-preprocessing="$emit('save-preprocessing', $event)"
      />
    </div>
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu'
import headerTabs from '@/components/organisms/header-tabs'
import preprocessingApplyMain from '@/components/organisms/preprocessing-apply/preprocessing-apply-main'
import preprocessingApplyPopup from '@/components/organisms/preprocessing-apply/preprocessing-apply-popup'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    preprocessingApplyMain,
    preprocessingApplyPopup,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    hoverMenu: Object,
    body: Object,
    loading: Boolean,
    loadingInit: Boolean,
    mainData: Object,
    showData: Object,
    notFound: Boolean,
    disableClick: Boolean,
    isLargeCSV: Boolean,
    datasetSizeWarning: Object,
    autoPreprocessingApplied: Boolean
  },
  computed: {
    datasetName() {
      if (this.body.pageBody.datasetList) {
        return this.body.pageBody.datasetList[this.mainData.datasetId]?.name
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
