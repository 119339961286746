<template>
  <dataset-list
    v-bind="data"
    :projectId="projectId"
    :projectItems="projectItems"
    :accountInfo="accountInfo"
    :datasetList="sortDatasetList"
    :datasetLoading="loading"
    :datasourceList="datasource"
    :datasourceLoading="datasourceLoading"
    :showDatasourceList="showDatasourceList"
    :headerTabs="headerTabs"
    :sidebar="sidebar"
    :uploadLoading="uploadLoading"
    :progress="progress"
    :maxProgress="maxProgress"
    :progressStatus="progressStatus"
    :deleteLoading="deleteLoading"
    :notFoundProject="notFoundProject"
    :disableClick="disableClick"
    :downloadLoading="downloadLoading"
    @add-new-data="showPopup('addNewData')"
    @delete-dataset="deleteDataset"
    @delete-datasource="deleteDataSource"
    @add-dataset-to-project="applyProjectAddDataset"
    @show-datasource="showDatasource($event)"
    @test-datasource="testDataSource($event)"
    @new-datasource="changeDataSource($event)"
    @show-dataset="showDataset($event)"
    @toggle-datasource-list="toggleDatasourceList($event)"
    @close-modal="closePopup($event)"
    @show-table="showTable($event)"
    @change-tab="changeTabContent($event)"
    @on-file-input="onFileInput($event)"
    @datasource-input="datasourceInput($event)"
    @icon-menu-click="iconMenuClick($event)"
    @import-dataset-loading="showImportDatasets($event)"
    @set-datasource="setDatasource($event), showPopup('resetDatasource')"
    @start-uploading="uploadFile($event)"
    @sort-label="sortLabel($event)"
    @datasource-items="previewDataSourceItem($event)"
    @do-import="doImport($event)"
    @file-clear="AllfileClear"
    @input-copy-form="inputCopyForm($event)"
    @input-create-form="inputCreateForm($event)"
    @get-and-set-sql-list="getAndSetSqlList"
    @get-sql-list="getSqlList"
    @show-sql-edit="showSqlEdit"
    @select-sql-preview-table="selectSqlPreviewTable"
  />
</template>

<script>
import { uploadViaSocket } from '@/lib/upload.js'
import { datasetValidator } from '@/lib/validator/dataset.js'
import { datasourceValidator } from '@/lib/validator/datasource.js'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

import datasetList from '@/components/templates/dataset-list.vue'

export default {
  components: {
    datasetList
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()

      await vm.fetchAccountInfo()
      await vm.loadProjectDatasets()
      vm.setUsageRestriction({ projectId: vm.projectId })

      await vm.loadProjectDetail(vm.projectId)
      await vm.fetchDataSources()
    })
  },
  computed: {
    ...mapGetters('datasets', {
      allDatasetList: 'datasetList',
      allDatasetLoading: 'datasetLoading',
      loadingDatasetList: 'loadingDatasetList'
    }),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasource', ['datasourceList', 'datasourceLoading', 'submitting']),
    ...mapGetters('services', ['services']),
    ...mapGetters('trainedAi', ['trainedAIs']),
    ...mapGetters('project', ['projectList', 'projectLoading']),

    datasource() {
      this.$delete(this.datasourceList, 'loading') // これがわかりにくいのであればstoreの方でloadingが渡ってこないように書き換える。
      const data = Object.values(this.datasourceList)
      return data
    },
    sortDatasetList() {
      /*
      テーブルのラベル選択することによってソート機能が動きます。
      一回押すとデータが降順、同じラベルを再度押すと昇順にデータがならびます。
      */
      if (!this.datasetList) return null
      const data = Object.values(this.datasetList)
      const key = this.sortKey
      const self = this
      if (!key) {
        return data
      } else {
        return data.sort(function (a, b) {
          if (self.reverse === key) {
            if (key === 'createTime') {
              return a.id > b.id ? 1 : -1
            }
            if (key === 'size') {
              return a.size > b.size ? 1 : -1
            }
          } else {
            if (key === 'createTime') {
              return a.id < b.id ? 1 : -1
            }
            if (key === 'size') {
              return a.size < b.size ? 1 : -1
            }
          }
          return console.error()
        })
      }
    },
    headerTabs() {
      return {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      }
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'dataset'
      }
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    datasetList() {
      if (this.projectId) {
        return this.projectItems
      } else {
        return this.allDatasetList
      }
    },
    ownModels() {
      if (
        this?.accountInfo?.accountId &&
        Object.keys(this.trainedAIs).length > 0
      ) {
        return Object.values(this.trainedAIs).filter(
          (m) => m.accountId === this.accountInfo.accountId
        ).length
      }
      return 0
    },
    datasetLoading() {
      if (this.projectId) {
        return this.projectItemsLoading
      } else {
        return this.allDatasetLoading
      }
    },
    loading() {
      return (
        this.datasetLoading || this.loadingDatasetList || this.projectLoading
      )
    },
    disableClick() {
      return this.submitting || this.submitDeleting || this.submitOther
    }
  },
  data() {
    return {
      sortKey: null,
      reverse: '',
      uploadLoading: false,
      showDatasourceList: false,
      projectItems: null,
      projectItemsLoading: true,
      progressSettingDataset: 0,
      progress: 0,
      maxProgress: 1,
      progressStatus: null,
      deleteLoading: false,
      submitDeleting: false,
      submitOther: false,
      downloadLoading: false,
      data: {
        body: {
          top: {
            usageRestriction: {
              basic: true,
              education_1: true,
              free: true
            }
          },
          checkedClear: false,
          addDataset: {
            importLoading: false,
            importError: null,
            uploadLearningDataErrors: { flag: false, errors: [] },
            uploadLearningDataWarnings: {
              fileType: null,
              reasonList: []
            }
          }
        },
        popup: {
          // ポップアップの情報
          uploadFile: null,
          fileInputValue: {
            // ファイルでインポートする際の値が入ります
            fileName: '',
            description: '',
            isDisabled: true,
            resetFileValue: false
          },
          copyInfo: {
            datasetFormValidate: {
              duplicate: null
            }
          },
          createInfo: {
            datasetFormValidate: {
              duplicate: null
            },
            datasourceFormValidate: {
              duplicate: null
            }
          },
          showDatasource: {
            // 登録済みのデータソース一覧ポップアップ
            loadingDatasourceItems: false,
            title: '',
            maxRows: 0,
            datasourceInfo: '',
            dataSourceItems: [],
            dataSourceListItemsData: [],
            dataSourceListItemsSqlData: [],
            importFromDataSourceId: null,
            importFromDataSourceTableName: null,
            loadingdatasourceItemsInfo: false,
            importErrorOnly: false,
            datasourceItemsInfo: null,
            variables: {
              showDatasource: 0,
              showDataset: 0
            },
            tableInputValue: {
              // 外部からインポートする際のインポートするカラムとデータセットの情報が入ります
              checked: [],
              name: '',
              description: ''
            },
            dataSourceSqlItems: [],
            loadingSqlTablePreview: false,
            sqlTablePreviewData: {}
          },
          addDatasetInfo: {
            loadingDatasourceItems: false,
            dataSourceSqlItems: [],
            dataSourceItems: []
          },
          resetDatasource: {
            resetData: false,
            connentTest: null,
            datasourceInfo: '',
            isAddDatasource: false,
            inTesting: false
          },
          showPopup: [], // 現在表示しているポップアップ
          deleteData: {},
          deleteDatasource: {},
          deletedId: '',
          intoProject: {},
          isLargeCsv: false
        }
      }
    }
  },
  methods: {
    ...mapActions('datasource', [
      'fetchDataSources',
      'deleteDataSource',
      'addDataSource',
      'deleteDataSource',
      'fetchSqlList',
      'fetchSqlTablePreviewData',
      'editBigQueryDataSource',
      'editSnowflakeDataSource',
      'editDataSource',
      'testDataSourceConnection',
      'testBigQueryDataSourceConnection',
      'importDatasource'
    ]),
    ...mapActions('datasets', ['deleteDatasets', 'downloadDataset', 'loadDatasetList']),
    ...mapActions('project', ['loadProjectDetail', 'projectAddDataset']),
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('models', ['deleteProgressTraining', 'setProgressTraining']),
    ...mapMutations('datasource', ['SET_SUBMITTING']),

    async loadProjectDatasets() {
      if (this.projectId) {
        this.projectItemsLoading = true
        try {
          const response = await this.$sendMessageAndReceive({
            action: 'getDataList',
            projectId: this.projectId
          })
          if (response.status !== 'error') {
            const obj = {}
            response.list.forEach((item) => {
              const fullId = item.id + '-' + item.accountId
              item.fullId = fullId
              obj[item.id] = item
            })
            this.projectItems = obj
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.projectItemsLoading = false
        }
      }
    },
    async changeDataSource(value) {
      this.submitOther = true
      const req = value
      if (value.dbtype === 'bigquery') {
        const data = value.credential
        if (!this.data.popup.resetDatasource.resetData) {
          req.action = 'addBigQueryDataSource'
        } else {
          req.action = 'editBigQueryDataSource'
          req.id = this.data.popup.showDatasource.datasourceInfo.id
        }
        await this.editBigQueryDataSource({ req, data })
      } else {
        if (!this.data.popup.resetDatasource.resetData) {
          req.action = 'addDataSource'
        } else {
          req.action = 'editDataSource'
          req.id = this.data.popup.showDatasource.datasourceInfo.id
        }
        await this.editDataSource(req)
      }
      this.submitOther = false
      this.closePopup('resetDatasource')
    },
    async testDataSource(value) {
      window.clearTimeout(this.timeoutID)
      this.data.popup.resetDatasource.connentTest = null
      this.data.popup.resetDatasource.inTesting = true

      let check
      if (value.dbtype === 'bigquery') {
        const data = value.credential
        check = await this.testBigQueryDataSourceConnection({ value, data })
      } else {
        check = await this.testDataSourceConnection(value)
      }
      const valid = check !== 'error'
      this.data.popup.resetDatasource.connentTest = valid
      this.data.popup.resetDatasource.inTesting = false

      this.timeoutID = window.setTimeout(
        function () {
          this.data.popup.resetDatasource.connentTest = null
        }.bind(this),
        5000
      )
    },
    sortLabel(e) {
      /*
        ラベルでソートします。
        データサイズのデータセットの作成日(id)でソートします。
        1回押すと昇順2回押すと降順にソートします
      */
      if (this.reverse === e.label) {
        this.reverse = ''
      } else if (this.sortKey === e.label) {
        this.reverse = e.label
      }
      this.sortKey = e.label
    },
    showPopup(e) {
      // ポップアップを表示
      this.data.popup.showPopup.push(e)
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.data.popup.resetDatasource.connentTest = null
      this.data.popup.showPopup = this.data.popup.showPopup.filter(
        (n) => n !== e
      )
    },
    toggleDatasourceList(e) {
      // データソースリストの表示非表示切り替え
      this.showDatasourceList = e
    },
    showTable(e) {
      // テーブルの表示
      this.previewDataSourceItem(e)
      this.data.body.addDataset.importError = null
      this.data.popup.showDatasource.variables.showDatasource = e
      this.data.popup.showDatasource.variables.showDataset = e.dataset
    },
    showSqlEdit(id) {
      console.log(this.data.body.addDataset)
      this.data.body.addDataset.importError = null
      this.data.popup.showDatasource.sqlTablePreviewData = {}
      this.data.popup.showDatasource.importFromDataSourceId = id
    },
    // 新規データセットの追加でファイルをアップしたときの処理
    onFileInput(file) {
      this.data.popup.fileInputValue.resetFileValue = false
      this.initUploadState()

      if (
        this.accountInfo.totalDataSize + file.size >
        this.accountInfo.planDetail.maxDataSize
      ) {
        this.data.body.addDataset.uploadLearningDataErrors.flag = true
        this.data.body.addDataset.uploadLearningDataErrors.sizeInfo = {
          currentTotalSize: this.accountInfo.totalDataSize,
          fileSize: file.size,
          newTotalSize: this.accountInfo.totalDataSize + file.size,
          maxSize: this.accountInfo.planDetail.maxDataSize
        }
        // this.$refs.uploadLearningData.reset();
        this.$nextTick(() => {
          this.data.popup.uploadFile = null
          this.data.popup.fileInputValue.resetFileValue = true
          this.showPopup('upperLimitDataSize')
        })
      } else {
        this.data.popup.uploadFile = file
        this.data.popup.fileInputValue.fileName = file.name.substr(
          0,
          file.name.lastIndexOf('.')
        )
      }
    },
    initUploadState: function () {
      this.data.body.addDataset.uploadLearningDataErrors = Object.assign(
        this.data.body.addDataset.uploadLearningDataErrors,
        {
          flag: false,
          message: []
        }
      )

      this.data.body.addDataset.uploadLearningDataWarnings.fileType = null
      this.data.body.addDataset.uploadLearningDataWarnings.reasonList = []
    },
    setDatasource(e) {
      // 新規データセットの追加でデータソースを入力したときのテスト
      this.data.popup.resetDatasource.datasourceInfo = e.datasourceInfo
      if (Object.keys(e.datasourceInfo).length === 0) {
        this.data.popup.resetDatasource.resetData = false
      } else {
        this.data.popup.resetDatasource.resetData = true
      }
    },
    async showImportDatasets(e) {
      // データソースのリストを開いた時の処理
      this.data.popup.showDatasource.loadingDatasourceItems = true
      let items = null
      const datasourceId = this.datasourceList[e].id
      if (
        this.data.popup.showDatasource.dataSourceListItemsData.some(
          (x) => x.id === datasourceId
        )
      ) {
        this.data.popup.showDatasource.loadingDatasourceItems = false
      } else {
        try {
          const res = await this.$sendMessageAndReceive({
            action: 'listDataSourceItems',
            id: datasourceId
          })
          items = res.result
          if (res.status === 'error') {
            throw res
          }
          this.data.popup.showDatasource.dataSourceListItemsData.push({
            items: items,
            id: datasourceId,
            error: false
          })
          this.data.popup.showDatasource.loadingDatasourceItems = false
        } catch (ex) {
          items = 'error'
          this.log_info(ex)
          this.data.popup.showDatasource.loadingDatasourceItems = false
          this.data.popup.showDatasource.dataSourceListItemsData.push({
            items: [],
            id: datasourceId,
            error: true
          })
        }
      }
    },
    async showDatasource(e) {
      // データソース一覧からデータソースを選択したときに動作する
      this.data.popup.showDatasource.loadingDatasourceItems = true
      this.data.popup.showDatasource.datasourceInfo = e.datasourceInfo
      this.data.popup.showDatasource.title = e.datasourceInfo.name
      this.data.popup.showDatasource.importErrorOnly = false
      this.data.popup.showDatasource.dataSourceItems = []
      this.data.body.addDataset.importError = null
      this.showPopup('showDatasource')
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'listDataSourceItems',
          id: e.datasourceInfo.id
        })
        if (res.status === 'error') {
          throw res
        }
        this.data.popup.showDatasource.dataSourceItems = res.result
        this.data.popup.showDatasource.loadingDatasourceItems = false
      } catch (ex) {
        this.log_info(ex)
        this.data.popup.showDatasource.loadingDatasourceItems = false
        this.data.popup.showDatasource.importErrorOnly = true
      }
    },
    async getAndSetSqlList(id) {
      const showDatasource = this.data.popup.showDatasource

      const check = showDatasource.dataSourceListItemsSqlData.some((item) => {
        return item.id === id
      })
      if (check) return

      showDatasource.loadingDatasourceItems = true

      const res = await this.fetchSqlList(id)
      showDatasource.dataSourceListItemsSqlData.push({
        items: res,
        id: id
      })

      showDatasource.loadingDatasourceItems = false
    },
    async getSqlList(id) {
      this.data.popup.showDatasource.loadingDatasourceItems = true
      this.data.popup.showDatasource.dataSourceSqlItems =
        await this.fetchSqlList(id)
      this.data.popup.showDatasource.loadingDatasourceItems = false
    },
    async selectSqlPreviewTable(payload) {
      this.data.popup.showDatasource.loadingSqlTablePreview = true

      this.data.popup.showDatasource.sqlTablePreviewData =
        await this.fetchSqlTablePreviewData(payload)

      this.data.popup.showDatasource.loadingSqlTablePreview = false
    },
    async previewDataSourceItem(e) {
      // インポートされたデータソースの詳細を取りに行くメソッド
      this.data.popup.showDatasource.loadingdatasourceItemsInfo = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'previewDataSourceItem',
          id: e.datasourceId,
          table: e.table.name
        })
        this.data.popup.showDatasource.importFromDataSourceId = e.datasourceId
        this.data.popup.showDatasource.importFromDataSourceTableName =
          e.table.name
        this.data.popup.showDatasource.maxRows = e.table.n_rows
        this.data.popup.showDatasource.datasourceItemsInfo = res.result
      } catch (ex) {
        this.log_info(ex)
      }
      this.data.popup.showDatasource.loadingdatasourceItemsInfo = false
    },
    // データソースからデータセットをインポートする時に動作する。
    doImport: async function (e) {
      this.data.body.addDataset.importLoading = true
      this.data.body.addDataset.importError = null
      try {
        const req = {
          action: 'importFromDataSource',
          id: e.id,
          table: e.table ?? null,
          name: e.name,
          description: e.description,
          columns: e.checked,
          import_query: e.import_query,
          projectId: this.projectId
        }
        if (!isNaN(e.columns)) {
          // 一つ目のifが行数を制限している時に通過する関数
          if (e.columns !== null) {
            req.limit = parseInt(e.columns, 10)
            // 二つ目のifがmaxRows以上の大きさの行数を入力された時に正常な行数をreqに渡す関数
            if (e.columns > this.data.popup.showDatasource.maxRows) {
              req.limit = parseInt(this.data.popup.showDatasource.maxRows, 10)
            }
          }
        }

        const res = await this.importDatasource(req)

        if (res.status === 'error') {
          this.log_info(res)
        } else {
          if (this.projectId !== null) {
            await this.loadProjectDatasets()
          } else {
            throw new Error('deprecated')
          }
          this.closePopup('showDatasource')
          this.closePopup('addNewData')
        }
      } catch (ex) {
        this.log_info(ex)
        if (e.import_query) {
          this.data.body.addDataset.importError = 'sql'
        } else {
          this.data.body.addDataset.importError = 'default'
        }
      } finally {
        await this.fetchAccountInfo()
        this.showDatasourceList = false
        this.data.body.addDataset.importLoading = false
      }
    },
    showDataset(e) {
      // データ詳細ページに飛ぶ
      this.$router.push({
        name: 'datasetDetail',
        params: { id: e.id + '-' + e.accountId }
      })
    },
    async deleteDataset(targets) {
      // データセットの削除機能
      this.submitDeleting = true
      this.data.body.checkedClear = false
      this.deleteLoading = true
      const datasets = targets.map((x) => ({
        id: x.id,
        accountId: x.accountId
      }))
      try {
        await this.deleteDatasets(datasets)
        await this.loadProjectDatasets()
      } finally {
        this.deleteLoading = false
        this.submitDeleting = false
      }
      this.closePopup('deleteData')
      this.data.popup.deleteData = {}
      this.data.body.checkedClear = true
    },

    async deleteDataSource(targets) {
      // データソースの削除機能 // 削除するデータソースのidを取得します。
      this.submitDeleting = true
      this.data.body.checkedClear = false
      const datasource = targets.map((x) => ({ id: x.id }))
      const request = {
        action: 'deleteDataSourceMulti',
        listId: datasource
      }
      try {
        const res = await this.$sendMessageAndReceive(request)
        if (res.status === 'error') {
          throw res
        } else {
          await this.fetchDataSources()
        }
      } finally {
        this.submitDeleting = false
      }
      this.closePopup('deleteDatasource')
      this.data.popup.deleteDatasource = {}
      this.data.body.checkedClear = true
    },
    async uploadFile(file) {
      this.uploadLoading = true

      const fileSize = this.data.popup.uploadFile.size
      const fileName = this.data.popup.uploadFile.name

      this.maxProgress = fileSize
      this.progressSettingDataset = 0
      this.progressStatus = 'startUploading'
      this.data.body.addDataset.uploadLearningDataErrors.errors = []
      this.data.body.addDataset.uploadLearningDataWarnings.fileType = null
      this.data.body.addDataset.uploadLearningDataWarnings.reasonList = []

      const request = {
        action: 'startUploading',
        name: file.name,
        description: file.description,
        fileSize: fileSize,
        fileName: fileName,
        projectId: this.projectId
      }

      // let uploadDataset = null

      try {
        const startRes = await this.$sendMessageAndReceive(request)
        const messageId = startRes.m_id

        const { status, message } = startRes
        if (status === 'error') throw new Error(message)

        const uploadTask = uploadViaSocket(
          this.$socket,
          this.data.popup.uploadFile,
          {
            chunkSize: 1e5,
            header: {
              action: 'uploadingData',
              m_id: messageId
            }
          }
        )
        await Promise.all([
          uploadTask,
          this.$watchProgress(messageId, {
            getTotalProgress: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = res.total
            },
            startPreparingZipData: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = 1
            },
            uploadingLearningData: (res) => {
              this.progressStatus = 'uploadingLearningData'
              this.maxProgress = res.totalSize
              this.progress = res.loadedSize
            },
            uploaded: (res) => {},
            startPreparingData: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = 1
              this.data.popup.isLargeCsv = res?.isLarge ?? false
            },
            preparingData: (res) => {
              this.progress = res.progress
            },
            dataWarning: (res) => {
              this.data.body.addDataset.uploadLearningDataWarnings.fileType =
                res.fileType
              this.data.body.addDataset.uploadLearningDataWarnings.reasonList =
                res.warnings
            }
          })
        ])
        this.progress = this.maxProgress

        if (this.projectId) {
          await this.loadProjectDatasets()
        } else {
          throw new Error('deprecated')
        }
        await this.fetchAccountInfo()

        this.closePopup('addNewData')
        this.uploadLoading = false

        this.clearForms()
        this.progressStatus = null
        this.progress = 0
        this.maxProgress = 1
      } catch (ex) {
        this.log_info(ex)

        this.progressStatus = null
        this.data.body.addDataset.uploadLearningDataErrors.errors.push({
          message: ex.message,
          file: ex?.error_info?.file
        })
        this.uploadLoading = false
      } finally {
        this.data.popup.isLargeCsv = false
        this.SET_SUBMITTING(false)
      }
    },
    clearForms() {
      // データセットの登録に成功した時に入力していたデータをリセットします。
      this.data.popup.uploadFile = null
    },
    async applyProjectAddDataset(target) {
      /**
        accountId: "accountId"
        dataId: "1234"
        project:{
          description: ""
          name: "name"
          tags: []
        }
      */
      this.submitOther = true
      this.data.body.checkedClear = false
      if (target.project.new) {
        await this.projectAddDataset({
          targets: target.targets,
          project: target.project
        })

        this.$router.push({
          name: 'projectList'
        })
      } else {
        await this.projectAddDataset({
          targets: target.targets,
          projectId: target.project.id
        })
      }
      await this.loadProjectDatasets()
      this.submitOther = false
      this.closePopup('intoProject')
      this.data.popup.intoProject = {}
      this.data.body.checkedClear = true
    },
    // データセットにデータを入れる時に走るバリデーション
    fileCheck(file) {
      if (
        this.accountInfo.totalDataSize + file.size >
        this.accountInfo.planDetail.maxDataSize
      ) {
        this.uploadLearningDataErrors.flag = true
        this.uploadLearningDataErrors.errors.push({
          message: 'UPLOAD_DATA_EXCEED_MAX_DATA_SIZE'
        })
        this.uploadLearningDataErrors.sizeInfo = {
          currentTotalSize: this.accountInfo.totalDataSize,
          fileSize: file.size,
          newTotalSize: this.accountInfo.totalDataSize + file.size,
          maxSize: this.accountInfo.planDetail.maxDataSize
        }
        // this.$refs.uploadLearningData.reset();
        this.selectedFile = null
        this.fileClear()
      } else {
        this.newData.name = file.name.substr(0, file.name.lastIndexOf('.'))
      }
    },
    async iconMenuClick({ isDatasource, name, checked }) {
      if (isDatasource) {
        // データソース
        switch (name) {
          case 'delete': // 何のデータを削除するかを判別してtargetsにいれる
            this.data.popup.deleteDatasource = {
              targets: checked.map((id) =>
                this.datasourceList.find((item) => item.id === id)
              )
            }
            this.showPopup('deleteDatasource')
            break
        }
      } else {
        // データセット
        switch (name) {
          case 'download': {
            this.downloadLoading = true
            const target = this.datasetList[checked[0]]
            await this.downloadDataset({
              name: target.name,
              dataId: target.id,
              accountId: target.accountId,
              type: target.type
            })
            window.setTimeout(
              function () {
                this.downloadLoading = false
              }.bind(this),
              1000
            )
            break
          }
          case 'preprocAndEda':
            this.$router.push({
              name: 'preprocessingApplyNonePreproc',
              params: { datasetId: checked[0] }
            })
            break
          case 'combine':
            this.$router.push({
              name: 'datasetCombine',
              params: { datasetId: checked[0] }
            })
            break
          case 'inference':
            this.$router.push({
              name: 'inferenceProject',
              params: { projectId: this.projectId },
              query: { datasetId: checked[0] }
            })
            break
          case 'learning':
            if (this.accountInfo.planDetail.numModel > this.ownModels) {
              const dataset = Object.values(this.datasetList).find(
                (x) => x.id === checked[0]
              )
              const datasetType = dataset.type
              const datasetAccountId = dataset.accountId

              if (datasetType === 'structured') {
                /* 前処理状態を確認 */
                const res = await this.$sendMessageAndReceive({
                  action: 'getDataInfo',
                  dataId: dataset.id,
                  accountId: dataset.accountId
                })
                if (
                  res.detail?.preprocessing?.version > 0 &&
                  dataset.accountId !== this.accountInfo.accountId
                ) {
                  this.$notify({
                    group: 'alerts',
                    type: 'danger',
                    title: this.$t(
                      'datasetSetting.cautions.inPreprocessingOtherDataset'
                    ),
                    duration: 5000,
                    data: {
                      dismissible: false
                    }
                  })
                  return
                }
              }

              this.deleteProgressTraining({ projectId: this.projectId })

              this.setProgressTraining({
                item: this.projectId,
                setType: 'project'
              })
              this.setProgressTraining({
                item: checked[0],
                setType: 'dataset',
                projectId: this.projectId,
                datasetType: datasetType,
                datasetAccountId: datasetAccountId
              })

              this.$gtmDataLayer.sendEvent('startTraining', 'fromDatasetList')
              if (datasetType === 'structured') {
                this.$router.push({
                  name: 'selectTargetColumn',
                  params: { projectId: this.projectId, datasetId: checked[0] }
                })
              } else {
                this.$router.push({
                  name: 'trainRecipeList',
                  params: { projectId: this.projectId }
                })
              }
            } else {
              this.showPopup('upperLimitTrainedAi')
            }
            break
          case 'project':
            this.data.popup.intoProject = {
              targets: checked.map((id) => this.datasetList[id])
            }
            this.showPopup('intoProject')
            break
          case 'project-create':
            this.data.popup.intoProject = {
              targets: checked.map((id) => this.datasetList[id]),
              newProject: true
            }
            this.showPopup('intoProject')
            break
          case 'delete':
            this.data.popup.deleteData = {
              targets: checked.map((id) => this.datasetList[id])
            }
            this.showPopup('deleteData')
            break
        }
      }
    },
    inputCopyForm: function (obj) {
      const { type, form } = obj

      if (type === 'dataset') {
        this.data.popup.copyInfo.datasetFormValidate = datasetValidator(
          this.datasetList,
          form
        )
      }
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'dataset') {
        this.data.popup.createInfo.datasetFormValidate = datasetValidator(
          this.datasetList,
          form
        )
      } else if (type === 'datasource') {
        if (this.data.popup.resetDatasource.resetData) {
          // データソース再設定の時のみ、こちらのif文を通ります。
          const datasource = this.datasourceList
            .filter(
              (datasource) =>
                datasource.id !==
                this.data.popup.showDatasource.datasourceInfo.id
            )
            .reduce((result, datasource, index) => {
              result[index] = datasource
              return result
            }, {})
          this.data.popup.createInfo.datasourceFormValidate =
            datasourceValidator(datasource, form)
        } else {
          this.data.popup.createInfo.datasourceFormValidate =
            datasourceValidator(this.datasource, form)
        }
      }
    },
    AllfileClear() {
      this.clearForms()
      this.data.body.addDataset.uploadLearningDataErrors.errors = []
    },
    setUsageRestriction: function ({ projectId }) {
      const projectInfo = this.projectList[projectId]
      const sourceTemplateInfo = projectInfo.sourceTemplate

      const usageRestriction =
        sourceTemplateInfo !== null
          ? sourceTemplateInfo.usageRestriction
          : {
              basic: true,
              education_1: true,
              free: true
            }

      this.$set(this.data.body.top, 'usageRestriction', usageRestriction)
    }
  }
}
</script>
