<template>
  <div
    v-if="!['Input', 'SqlInput'].includes(layer.type)"
    :ref="'block' + layer.id"
    class="block"
    :class="{
      'block-active': activeBlock === layer.id,
      'block-scroll-x': useScrollX
    }"
    @click.stop="$emit('block-click', layer.id), checkPos(layer.id)"
  >
    <div class="type">
      <div class="icon">
        <icons :iconName="layerIconName" />
      </div>
      <texts :text="$t('preprocessing.layerNames.' + layer.type)" isBold />
    </div>
    <div v-if="!layer.type.endsWith('DeleteRows')" class="content">
      <texts
        :text="$t('preprocessing.chart.colum') + layer.column"
        size="small"
      />
    </div>
    <div
      :ref="'popup' + layer.id"
      class="popup-scroll-wrapper"
      :style="{ '--scrollX': scrollX + 'px', '--pos': popupPos + 'px' }"
    >
      <popup-balloon
        class="popup-wrap"
        :isShow="activeBlock === layer.id"
        :showLeft="showLeft"
        @close="$emit('popup-close')"
      >
        <div class="popup-inner">
          <div v-if="!layer.type.endsWith('DeleteRows')" class="popup-item">
            <text-with-title
              :title="$t('preprocessing.chart.balloon.colum')"
              :text="layer.column"
            />
          </div>
          <div v-if="layer.type.endsWith('DeleteRows')" class="popup-item">
            <text-with-title
              :title="$t('preprocessing.chart.balloon.condition')"
              :text="deleteRowDescription(layer)"
            />
          </div>
          <div class="popup-item">
            <text-with-title
              :title="$t('preprocessing.chart.balloon.type')"
              :text="$t('preprocessing.layerNames.' + layer.type)"
            />
          </div>
          <div
            v-if="
              [
                'Replace',
                'SqlReplace',
                'ReplaceDataNormalizer',
                'SqlReplaceDataNormalizer',
                'SqlReplaceNumericConverter',
                'ReplaceNumericConverter'
              ].includes(layer.type)
            "
            class="popup-item"
          >
            <div class="popup-title">
              <texts
                :text="$t('preprocessing.chart.balloon.conversionTable')"
                size="small"
                color="gray"
              />
            </div>
            <div class="popup-value">
              <div
                v-for="(oldValue, index) in layer.old_values"
                :key="index"
                class="popup-value-inner"
              >
                <div class="popup-value-item">
                  <texts :text="oldValue" size="small" />
                </div>
                <div class="popup-value-icon">
                  <icons iconName="change" size="min" />
                </div>
                <div class="popup-value-item">
                  <texts :text="layer.new_values[index]" size="small" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              [
                'ReplaceOneHot',
                'SqlReplaceOneHot',
                'ReplaceDummy',
                'SqlReplaceDummy'
              ].includes(layer.type)
            "
            class="popup-item"
          >
            <div class="popup-title">
              <texts
                :text="$t('preprocessing.chart.balloon.conversionTarget')"
                size="small"
                color="gray"
              />
            </div>
            <div class="popup-category">
              <texts
                v-for="(category, index) in layer.categories"
                :key="index"
                class="popup-category-inner"
                :text="category"
                size="small"
              />
            </div>
          </div>
        </div>
      </popup-balloon>
    </div>
  </div>
  <div
    v-else
    class="block-type-input"
    :class="{ 'block-active': activeBlock === layer.id }"
    @click="$emit('block-click', layer.id)"
  >
    <div class="type">
      <div class="icon">
        <icons :iconName="layerIconName" />
      </div>
      <texts :text="$t('preprocessing.layerNames.' + layer.type)" isBold />
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import texts from '@/components/atoms/text'
import popupBalloon from '@/components/atoms/popup-balloon.vue'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    icons,
    texts,
    popupBalloon,
    textWithTitle
  },
  data() {
    return {
      showLeft: false,
      popupPos: 0
    }
  },
  props: {
    /** 前処理のブロック */
    layer: Object,
    /** 選択中の前処理のブロック */
    activeBlock: Number,
    id: Number,
    useScrollX: Boolean,
    scrollX: Number
  },
  computed: {
    layerIconName() {
      const regs = 'Sql'
      return this.layer.type.replace(regs, '') + 'Preprocessing'
    }
  },
  methods: {
    deleteRowDescription(layer) {
      const connector = layer.connector
      const compareSign = {
        equal: '=',
        less_equal: '<=',
        greater_equal: '>='
      }
      const array = layer.conditions.map(
        (item) =>
          `${item.column_name} ${compareSign[item.compare_type]} ${
            item.compare_value
          }`
      )
      return array.join(connector)
    },
    checkPos(id) {
      if (this.useScrollX) {
        const target = this.$refs['block' + id]
        const windowWidth = document.body.clientWidth
        // 0.3546 = （320（ボックス幅） + 360（ポップアップ幅））/ 1920
        const posCheck =
          windowWidth - target.getBoundingClientRect().left <
          0.35416 * windowWidth
        this.showLeft = posCheck
        if (posCheck) {
          this.popupPos = target.offsetLeft - (404 / 1920) * windowWidth
        } else {
          this.popupPos =
            target.offsetLeft + target.offsetWidth + (12 / 1920) * windowWidth
        }
      } else {
        this.showLeft = id % 3 === 2
      }
    },
    outerClick(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    }
  },
  created() {
    this.outerClick(
      window,
      'click',
      function (e) {
        if (!this.$el.contains(e.target)) {
          this.$emit('popup-close')
        }
      }.bind(this)
    )
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.block {
  position: relative;
  width: calc(33.3% - #{$space-small});
  padding: $space-sub $space-base;
  border: $border-sub;
  background: $background;
  border-radius: adjustVW(8);
  cursor: pointer;
  box-shadow: $border-radius-transparent inset;
  transition: box-shadow $transition-base ease-in-out;
  &-type-input {
    @extend .block;
    width: adjustVW(160);
  }
  &-active {
    border: $border-transparent;
    box-shadow: $border-radius-emphasis inset;
  }
  &-scroll-x {
    position: inherit;
    flex-shrink: 0;
    width: adjustVW(320);
    .popup-wrap {
      top: inherit;
      right: initial;
      bottom: adjustVW(-20);
      left: 0;
      &::after {
        top: inherit;
        bottom: adjustVW(24);
      }
    }
    /* stylelint-disable */
    &::v-deep .balloon-left::after {
      /* stylelint-enable */
      right: initial;
      left: calc(100% - #{adjustVW(22)});
    }
    .popup-scroll-wrapper {
      position: absolute;
      left: calc(var(--pos) - var(--scrollX));

      --pos: 0;
      --scrollX: 0;
    }
  }
}

.type {
  display: flex;
  align-items: center;
  padding: 0 $space-small $space-sub $space-base;
  margin: 0 0 $space-sub 0;
  border-bottom: $border-sub;
  .block-type-input & {
    padding: 0 0 0 $space-base;
    margin: 0;
    border: none;
  }
}

.icon {
  margin: 0 $space-sub 0 0;
}

.content {
  padding: 0 $space-base;
}

.popup {
  &-inner {
    display: flex;
    flex-direction: column;
    width: adjustVW(360);
    max-height: adjustVW(440);
  }
  &-item {
    display: flex;
    flex-direction: column;
    margin: 0 0 $space-small;
    &:last-of-type {
      margin: 0;
    }
    &:nth-of-type(3) {
      overflow: hidden;
    }
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-category {
    overflow-y: auto;
    @include scrollbar;
    &-inner {
      margin: 0 0 $space-base;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-value {
    overflow-y: auto;
    @include scrollbar;
    &-inner {
      display: flex;
      align-items: center;
      padding: 0 $space-base $space-base;
      margin: 0 0 $space-base;
      border-bottom: $border-sub;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &-item {
      overflow: hidden;
      width: 100%;
    }
    &-icon {
      margin: 0 $space-sub;
    }
  }
}
</style>
