<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="data.isPrevent"
      :title="$t('preprocessing.applyingDataset.stopMoveTitle')"
      :saveButton="$t('preprocessing.applyingDataset.saveDataset')"
      :displaySaveButton="preProcHeadVersion !== 0"
      :leaveButton="$t('preprocessing.applyingDataset.movePreprocessing')"
      leaveButtonColor="sub"
      :displayCloseButton="preProcHeadVersion === 0"
      :closeButton="$t('preprocessing.applyingDataset.closePreventPopup')"
      @save-event="showPopup('saveDataset')"
    >
      <div class="line">
        <texts :text="$t('preprocessing.applyingDataset.stopMoveText1')" />
        <texts
          color="emphasis"
          :text="$t('preprocessing.applyingDataset.stopMoveText2')"
        />
      </div>
      <texts
        v-if="preProcHeadVersion !== 0"
        :text="$t('preprocessing.applyingDataset.stopMoveText3')"
      />
      <texts v-else class="text">
        {{ $t('preprocessing.applyingDataset.stopMoveText5') }}
      </texts>
      <texts :text="$t('preprocessing.applyingDataset.stopMoveText4')" />
    </prevent-leave>
    <prevent-leave
      ref="preventLeaveOtherTrainPages"
      v-model="isPreventOtherTrainPages"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButton')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.preprocessing')" />
      </texts>
    </prevent-leave>
    <div v-if="showData.showContent === 'main'">
      <train-preprocessing-apply
        v-bind="data"
        :projectInfo="projectInfo"
        :projectLoading="projectLoading"
        :progressTraining="progressTraining"
        :mainData="mainData"
        :showData="showData"
        :loadingInit="loadingInit"
        :notFound="notFound"
        :datasetSizeWarning="datasetSizeWarning"
        :disableClick="disableClick"
        :isLargeCSV="isLargeCSV"
        :sidebar="sidebar"
        :autoPreprocessingApplied="autoPreprocessingApplied"
        @apply-auto-preprocess="startAutoPreprocess()"
        @change-tab="changeTableTab($event)"
        @open-auto-preprocess="showPopup('applyAutoPreprocess')"
        @open-save-dataset="openSaveDataset($event)"
        @open-save-preprocessing="showPopup($event)"
        @reset-preprocessing="showPopup('resetPreprocessing')"
        @reset-preprocessing-confirm="resetVersion()"
        @back-version="backVersion()"
        @next-version="nextVersion()"
        @close-modal="closePopup($event), resetStatus($event)"
        @apply-preprocessing="applyPreprocessing()"
        @save-dataset="saveDataset($event)"
        @save-preprocessing="savePreprocessing($event)"
        @select-preprocessing="selectPreprocessing($event)"
        @close-error-popup="closeErrorPopup()"
        @show-detail="showDetail($event)"
        @show-statistics-detail="showStatisticsDetail($event)"
        @input-create-form="inputCreateForm($event)"
        @not-prevent="notPrevent()"
        @restart-prevent="restartPrevent"
        @go-dataset-list-page="goDatasetListPage()"
        @go-detail="goDetail()"
        @back-column-select="backColumnSelect()"
        @page-back="pageBack"
        @get-statistics="getLearningDataForAnalysis"
        @get-outlier="getLearningDataForAnalysis"
        @do-delete-rows="clickDeleteRows"
      />
    </div>
    <div v-if="showData.showContent === 'column'">
      <train-preprocessing-apply-detail
        v-bind="data"
        :projectInfo="projectInfo"
        :projectLoading="projectLoading"
        :progressTraining="progressTraining"
        :showData="showData"
        :mainData="mainData"
        :columnData="columnData"
        :loadingInit="loadingInit"
        :disableClick="disableClick"
        :isLargeCSV="isLargeCSV"
        :sidebar="sidebar"
        :incorrectOrder="incorrectOrder"
        @change-tab="changeGraphTab($event)"
        @open-save-preprocessing="showPopup($event)"
        @reset-preprocessing="showPopup('resetPreprocessing')"
        @reset-preprocessing-confirm="resetVersion()"
        @back-version="backVersion()"
        @next-version="nextVersion()"
        @close-modal="closePopup($event)"
        @save-dataset="saveDataset($event)"
        @save-preprocessing="savePreprocessing($event)"
        @apply-one-hot="applyOneHot()"
        @apply-dummy="applyDummy()"
        @apply-label="applyLabel($event)"
        @change-null="changeNull($event)"
        @drop-null="dropNull()"
        @drop-column="dropColumn()"
        @drop-outlier="dropOutlier()"
        @apply-standardize="clickApplyStandardize()"
        @apply-yeo-johnson="clickApplyYeoJohnson()"
        @apply-binning="clickApplyBinning($event)"
        @back-main="backMain()"
        @input-create-form="inputCreateForm($event)"
        @not-prevent="notPrevent"
        @restart-prevent="restartPrevent"
        @back-column-select="backColumnSelect()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { checkTrainingOrder } from '@/lib/progress-training'
import { checkPreprocessingDatasetSize } from '@/lib/preprocessing.js'
import { datasetValidator } from '@/lib/validator/dataset.js'
import { preprocValidator } from '@/lib/validator/preproc.js'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'
import setMountedTimer from '@/mixin/set-mounted-timer'

import trainPreprocessingApply from '@/components/templates/train/preprocessing.vue'
import trainPreprocessingApplyDetail from '@/components/templates/train/preprocessing-detail.vue'
import preventLeave from '@/components/molecules/prevent-leave'
import texts from '@/components/atoms/text'

export default {
  components: {
    trainPreprocessingApply,
    trainPreprocessingApplyDetail,
    preventLeave,
    texts
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loadingPage = true

      await vm.$waitConnected()

      const modelSettingInfo = await vm.getProgressTraining({
        projectId: parseInt(to.params.projectId)
      })

      const datasetId = to.params.id
      vm.mainData.datasetId = datasetId
      vm.mainData.objectiveColumn = modelSettingInfo?.targetColumn ?? []
      // TODO transitionTypeが機能していないので削除する 関連箇所が多いため注意 同様の判定を行う場合は /home/hika/matrixflow/new-ui/src/components/organisms/train/preprocessing/apply-detail-main.vue のようにisTrainを必要な箇所で渡す

      // 0: 予測する列の選択ページ, 1: プロジェクト管理

      vm.mainData.transitionType = to.params.type

      if (!vm.notFoundDataset) {
        const targetAccountId = vm.datasetList[datasetId].accountId
        if (
          !vm.trainingDatasetId ||
          vm.trainingDatasetId !== datasetId ||
          !vm.trainingDatasetAccountId ||
          vm.trainingDatasetAccountId !== targetAccountId
        ) {
          await vm.fetchDatasetDetail({
            dataId: datasetId,
            accountId: targetAccountId,
            includeGraph: false,
            includeData: false
          })
        }
        await vm.fetchSelectedLearningDataForAnalysis({
          type: 'structured',
          id: datasetId,
          ...vm.checkAndSetDataReq()
        })
      }

      await vm.loadProjectDetail(vm.projectId)
      const checkDatasetSize = checkPreprocessingDatasetSize({
        accountInfo: vm.accountInfo,
        dataset: vm.datasetList[datasetId]
      })

      vm.$set(vm.datasetSizeWarning, 'showPopup', checkDatasetSize.showPopup)
      vm.$set(
        vm.datasetSizeWarning,
        'datasetSize',
        checkDatasetSize.datasetSize
      )
      vm.$set(
        vm.datasetSizeWarning,
        'maxDataSize',
        checkDatasetSize.maxDataSize
      )
      vm.$set(
        vm.datasetSizeWarning,
        'totalDataSize',
        checkDatasetSize.totalDataSize
      )

      await vm.setModelSettingInfo(parseInt(to.params.projectId))

      vm.loadingPage = false

      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.projectId,
        stage: 'preprocessing',
        progressList: vm.modelSettingList
      })
      if (vm.incorrectOrder) {
        vm.showPopup('preventTrainingStatus')
      }
    })
  },
  async beforeRouteLeave(to, from, next) {
    this.isPreventOtherTrainPages = this.checkPrevent(to.name)

    if (this.isPreventOtherTrainPages) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeaveOtherTrainPages.$confirm()

      if (result) {
        this.data.isPrevent = false
        await this.resetPreProcessing()

        this.deleteProgressTraining({ projectId: this.projectId })
        this.setProgressTraining({
          item: this.projectId,
          setType: 'project'
        })

        // 前処理の state を一部リセット
        this.resetPreprocessingState()
        this.resetPreProcessingData()

        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        next(false)
      }
      // データ保存後にフラグの変更が呼び出し先の変数に反映されないので、呼び出し元でのフラグを設定
    } else if (this.data.isPrevent) {
      const result = await this.$refs.preventLeave.$confirm()

      if (result) {
        if (this.mainData.transitionType === '0' && !this.nextPageFlag) {
          // 戻るボタンなど、前処理を完了以外で遷移する場合は、前処理後に予測する列を選択するフラグを消す
          this.setProgressTraining({
            item: false,
            setType: 'laterSelectTargetColumn',
            projectId: this.projectId
          })
        }
        // 予測する列の選択に遷移する場合は前処理をリセット
        if (to.name === 'selectTargetColumn') {
          await this.resetPreProcessing()
        }

        // preventLeave 用 popup の二重発火の抑制
        this.data.isPrevent = false

        // 前処理の state を一部リセット
        this.resetPreprocessingState()
        this.resetPreProcessingData()

        next()
      } else {
        next(false)
      }
    } else {
      if (this.mainData.transitionType === '0' && !this.nextPageFlag) {
        // 戻るボタンなど、前処理を完了以外で遷移する場合は、前処理後に予測する列を選択するフラグを消す
        this.setProgressTraining({
          item: false,
          setType: 'laterSelectTargetColumn',
          projectId: this.projectId
        })
      }
      // 予測する列の選択に遷移する場合は前処理をリセット
      if (to.name === 'selectTargetColumn') {
        await this.resetPreProcessing()
      }

      // 前処理の state を一部リセット
      this.resetPreprocessingState()
      this.resetPreProcessingData()
      next()
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('preprocessings', [
      'EDARecipes',
      'autoPreprocessingApplied',
      'table',
      'columns',
      'preProcHeadVersion',
      'preProcLatestVersion',
      'graph',
      'describe',
      'table',
      'linePlotValues',
      'appliedChart',
      'boxPlotData',
      'loadingPreProc',
      'loadingEDARecipes',
      'loadingEDARecipeDetail',
      'loadingEDARecipeDelete'
    ]),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('trainingDataset', ['trainingDatasetId', 'trainingDatasetAccountId']),

    projectId() {
      return parseInt(this.$route.params.projectId)
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    },
    notFoundDataset() {
      const data = this.datasetList[this.mainData.datasetId]
      return (
        !(this.mainData.datasetId in this.datasetList) ||
        !(
          data.type === 'structured' &&
          data.accountId === this.accountInfo.accountId
        )
      )
    },
    notFoundType() {
      return ['0', '1'].indexOf(this.mainData.transitionType) < 0
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFound() {
      return this.notFoundDataset || this.notFoundType || this.notFoundProject
    },
    describeData() {
      if (this.describe.length > 0) {
        return this.describe
      } else if (
        this.datasetList[this.mainData.datasetId]?.describe &&
        this.datasetList[this.mainData.datasetId].describe.length > 0
      ) {
        return this.datasetList[this.mainData.datasetId].describe
      } else {
        return []
      }
    },
    // 統計情報用の列を設置
    showingStatisticsColumns() {
      // タイトル列は固定
      const showingStatisticsColumns = [
        {
          id: 0,
          name: '/'
        }
      ]
      return [...showingStatisticsColumns, ...this.columns]
    },
    disableClick() {
      return this.submitSave || this.submitReset
    },
    preprocessingList() {
      if (this.projectId === null) {
        return this.EDARecipes
      } else {
        return this.projectItem
      }
    },
    projectItem() {
      const projectItem = {}
      if (this.projectList[this.projectId]?.listEdaRecipe) {
        this.projectList[this.projectId].listEdaRecipe.forEach((item) => {
          projectItem[item.id] = item
        })
      }
      return projectItem
    },
    isLargeCSV() {
      return this.datasetList[this.mainData.datasetId]?.storage === 'db'
    },
    data() {
      return {
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 0
        },
        hoverMenu: {
          // ヘルプに表示される情報
          help: {
            searchTarget: {
              name: this.$t('help.categories.preprocessingApply')
            },
            searchResult: [
              {
                id: 1,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？',
                link: '#'
              },
              {
                id: 2,
                content: 'AIの新規作成はどのように進めればいいですか？',
                link: '#'
              },
              {
                id: 3,
                content:
                  '最も検索されている内容は、この検索フォームで最も検索されている５件を表示します。テキストは最大３行。',
                link: '#'
              },
              {
                id: 4,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？',
                link: '#'
              },
              {
                id: 5,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？'
              }
            ]
          },
          showHelp: false
        },
        body: {
          pageBody: {
            dataset: {
              // データセット詳細に表示するテーブル
              isStatics: false, // データセット詳細に表示するテーブルにグラフを表示するのかの判定
              tableColumns: this.columns,
              table: this.table
            },
            statistics: {
              // データセット詳細に表示する統計情報のテーブル
              isStatics: true,
              tableColumns: this.showingStatisticsColumns,
              table: this.describeData
            },
            preprocessingList: this.preprocessingList,
            datasetList: this.datasetList,
            graph: this.graph,
            linePlotValues: this.linePlotValues,
            boxPlotData: this.boxPlotData,
            loadingGraph: false
          },
          pageBottom: {
            preProcHeadVersion: this.preProcHeadVersion,
            preProcLatestVersion: this.preProcLatestVersion
          },
          appliedChart: this.appliedChart
        },
        isPrevent: this.preProcLatestVersion !== 0 && !this.nextPageFlag,
        loading:
          this?.loadingPreProc ||
          this?.loadingEDARecipes ||
          this?.loadingEDARecipeDetail ||
          this?.loadingEDARecipeDelete ||
          (this?.datasetLoading && !this.goNextLoading) ||
          this.loadingPage
      }
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    }
  },
  data() {
    return {
      mainData: {
        tabs: [
          // ページ上部のタブ
          {
            id: 0,
            name: this.$t('preprocessing.applyingDataset.tab.dataset'),
            iconName: 'dataset',
            value: 'dataset'
          },
          {
            id: 1,
            name: this.$t('preprocessing.applyingDataset.tab.statistics'),
            iconName: 'statistics',
            value: 'statistics'
          },
          {
            id: 2,
            name: this.$t('preprocessing.applyingDataset.tab.deleteRows'),
            iconName: 'delete',
            value: 'deleteRows'
          }
        ],
        activeTab: 0,
        visibleContents: 'dataset',
        preprocessingId: null,
        datasetId: null,
        isUsed: false,
        objectiveColumn: [],
        transitionType: '0',
        fromProjectPage: false,
        errorPopup: {
          isAutoProcess: false,
          isShow: false,
          text: ''
        },
        // ポップアップの情報
        popup: {
          createInfo: {
            datasetFormValidate: {
              duplicate: undefined
            },
            preprocFormValidate: {
              duplicate: undefined
            }
          },
          // 現在表示しているポップアップ
          showPopup: [],
          stopMove: {}
        },
        loadingAll: false,
        loadingDetailPage: false,
        settedAll: false
      },
      columnData: {
        tabs: [
          {
            id: 0,
            name: this.$t('common.graph.histogram'),
            iconName: 'histogram',
            value: 'histogram'
          },
          {
            id: 1,
            name: this.$t('common.graph.boxPlot'),
            iconName: 'boxPlot',
            value: 'boxPlot'
          }
        ],
        activeTab: 0,
        visibleContents: 'histogram',
        popup: {
          // ポップアップの情報
          showPopup: [], // 現在表示しているポップアップ
          stopMove: {}
        }
      },
      showData: {
        showContent: 'main',
        showColumn: {}
      },
      sidebar: {
        // サイドバーに表示する情報
        activeLink: 'preprocessingSetting',
        status: 'setting'
      },
      loadingPage: false,
      loadingInit: false,
      preventLeave: false,
      nextPageFlag: false,
      notChangeFlag: false,
      updateDatasetId: null,
      updateDatasetAccountId: null,
      submitSave: false,
      submitReset: false,
      isPreventOtherTrainPages: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null,
      incorrectOrder: null,
      goNextLoading: false,
      datasetSizeWarning: {
        datasetSize: null,
        maxDataSize: null,
        showPopup: false,
        totalDataSize: null
      },
      progressTraining: null
    }
  },
  methods: {
    ...mapActions('preprocessings', [
      'applyAutoPreprocess',
      'moveVersion',
      'resetPreProcessing',
      'replaceRowValuesOneHot',
      'replaceRowValuesDummy',
      'replaceRowValues',
      'deleteColumn',
      'deleteOutlier',
      'fillNullValue',
      'dropNullValue',
      'applyEDARecipe',
      'fetchSelectedLearningDataForAnalysis',
      'getLinePlotData',
      'getSelectedLearningDataBoxPlot',
      'fetchEDARecipeList',
      'saveEDARecipe',
      'savePreProcessedData',
      'resetPreprocessingState',
      'resetPreProcessingData',
      'pageBackMainState',
      'applyStandardize',
      'applyYeoJohnson',
      'applyBinning',
      'applyDeleteRows'
    ]),
    ...mapActions('datasets', ['loadDatasetList']),
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('models', ['deleteProgressTraining', 'getProgressTraining', 'setProgressTraining']),
    ...mapActions('trainingDataset', ['fetchDatasetDetail', 'setDatasetIdAccountId', 'setDatasetPreprocessing']),

    async startAutoPreprocess() {
      this.closePopup('applyAutoPreprocess')
      this.loadingPage = true

      try {
        await this.applyAutoPreprocess({
          dataId: this.mainData.datasetId,
          version: this.preProcHeadVersion,
          predictColumns: this.mainData?.objectiveColumn
        })
        if (this.preProcHeadVersion === 0) {
          this.showPopup('noAutoPreprocessingInTraining')
        }
      } catch (e) {
        console.log('c')
        const errorName = this.$t(`preprocessing.error.${e.name}`)
          ? e.name
          : 'UNKNOWN_ERROR'
        const text = this.$t(
          `preprocessing.error.${errorName}`,
          e === null
            ? {}
            : {
                ...e,
                preprocErrorBlockName:
                  e.preprocErrorBlockName &&
                  this.$t(
                    `preprocessing.layerNames.${e.preprocErrorBlockName}`
                  ),
                preprocErrorBlockInputColumns:
                  e.preprocErrorBlockInputColumns &&
                  e.preprocErrorBlockInputColumns.map((x) => `'${x}'`).join()
              }
        )
        this.mainData.errorPopup.isAutoProcess = true
        this.mainData.errorPopup.isShow = true
        this.mainData.errorPopup.text = text
      } finally {
        await this.updateInfo(false)

        this.loadingPage = false
      }
    },
    showPopup(e) {
      // ポップアップを表示
      this.mainData.popup.showPopup.push(e)
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.mainData.popup.showPopup = this.mainData.popup.showPopup.filter(
        (n) => n !== e
      )

      if (e === 'datasetSizeExceedCaution') {
        this.$set(this.datasetSizeWarning, 'afterSave', false)
        this.$set(this.datasetSizeWarning, 'showPopup', false)
      }
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    },
    resetStatus(e) {
      if (e === 'saveDataset') {
        this.sidebar.status = 'setting'
      }
    },
    changeTableTab(e) {
      this.mainData.visibleContents = e
      this.mainData.activeTab = this.mainData.tabs.findIndex(
        (item) => item.value === e
      )
    },
    async changeGraphTab(e) {
      this.data.body.pageBody.loadingGraph = true
      this.columnData.visibleContents = e
      this.columnData.activeTab = this.columnData.tabs.findIndex(
        (item) => item.value === e
      )

      if (
        e === 'boxPlot' &&
        this.boxPlotData?.columnName !== this.showData.showColumn.name
      ) {
        await this.getSelectedLearningDataBoxPlot({
          columnName: this.showData.showColumn.name
        })
      }
      this.data.body.pageBody.loadingGraph = false
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'preproc') {
        const edaRecipes =
          this.projectId !== null
            ? this.projectList[this.projectId].listEdaRecipe
            : []

        this.mainData.popup.createInfo.preprocFormValidate = preprocValidator(
          edaRecipes,
          form
        )
      } else if (type === 'dataset') {
        const datasets =
          this.projectId !== null
            ? this.projectList[this.projectId].listData
            : []

        this.mainData.popup.createInfo.datasetFormValidate = datasetValidator(
          datasets,
          form
        )
      }
    },
    async openSaveDataset(e) {
      if (this.mainData.transitionType !== '0') {
        // 予測する列の選択ページ以外からきている場合は保存用のポップアップを開く
        this.showPopup(e)
        this.sidebar.status = 'confirm'
      } else if (this.preProcLatestVersion !== 0) {
        if (this.preProcHeadVersion === 0) {
          // 予測する列の選択ページからきていて、加工したあとにすべての前処理を戻った場合は、リセットして次の画面に遷移
          await this.resetVersion()
          this.notChangeFlag = true
          this.nextPage()
        } else {
          // 予測する列の選択ページからきていて、加工している場合は保存用のポップアップを開く
          this.showPopup(e)
          this.sidebar.status = 'confirm'
        }
      } else {
        // 予測する列の選択ページからきていて、加工していない場合は次の画面へ遷移
        this.notChangeFlag = true
        this.nextPage()
      }
    },
    nextPage() {
      // preventLeaveの強制解除
      this.nextPageFlag = true
      this.correctTransition = true
      const laterSelectTargetColumn =
        this.progressTraining?.laterSelectTargetColumn
      // 次ページへの遷移
      if (this.mainData.transitionType === '0' && !laterSelectTargetColumn) {
        // 予測する列を選択している場合
        if (this.notChangeFlag) {
          this.setProgressTraining({
            item: null,
            setType: 'preprocessing',
            projectId: this.projectId
          })
        } else {
          this.setProgressTraining({
            item: this.updateDatasetId,
            setType: 'preprocessing',
            projectId: this.projectId
          })
        }
        this.$router.push({
          name: 'trainRecipeList'
        })
      } else if (
        this.mainData.transitionType === '0' &&
        laterSelectTargetColumn
      ) {
        // 予測する列を選択していない場合
        let datasetId = null
        if (this.notChangeFlag) {
          datasetId = this.mainData.datasetId

          this.setProgressTraining({
            item: null,
            setType: 'preprocessing',
            projectId: this.projectId
          })
        } else {
          datasetId = this.updateDatasetId

          this.setProgressTraining({
            item: this.updateDatasetId,
            setType: 'preprocessing',
            projectId: this.projectId
          })
        }
        this.$router.push({
          name: 'selectTargetColumn',
          params: {
            projectId: this.projectId,
            datasetId: datasetId
          }
        })
      } else {
        // プロジェクト管理からきている場合
        let datasetId = null
        if (this.notChangeFlag) {
          datasetId = this.mainData.datasetId
        } else {
          datasetId = this.updateDatasetId
        }
        this.$router.push({
          name: 'datasetProjectDetail',
          params: {
            projectId: this.projectId,
            id: datasetId + '-' + this.updateDatasetAccountId
          }
        })
      }
    },
    async saveDataset({ dataset, preprocessing, preprocessingSave }) {
      this.submitSave = true
      this.loadingPage = true
      try {
        // 前処理の保存
        if (preprocessingSave) {
          await this.saveEDARecipe({
            dataId: this.mainData.datasetId,
            version: this.preProcHeadVersion,
            name: preprocessing.name,
            description: preprocessing.description,
            projectId: this.projectId
          })
        }
        // データの保存
        const res = await this.savePreProcessedData({
          dataId: this.mainData.datasetId,
          version: this.preProcHeadVersion,
          name: dataset.name,
          description: dataset.description,
          projectId: this.projectId
        })

        this.updateDatasetId = res?.fileId
        this.updateDatasetAccountId = res?.accountId

        this.loadingPage = false

        if (
          this.updateDatasetId?.length > 0 &&
          this.updateDatasetAccountId?.length > 0
        ) {
          // 次の画面へ遷移
          this.goNextLoading = true

          await this.loadProjectDetail(this.projectId)

          // trainingDatasetに情報を登録
          const reqDataset = {
            dataId: this.updateDatasetId,
            accountId: this.datasetList[this.updateDatasetId].accountId,
            includeGraph: false,
            includeData: false
          }
          await this.fetchDatasetDetail(reqDataset)
          this.setDatasetPreprocessing(false)

          this.nextPage()
        } else if (res?.code === 22003) {
          this.$set(this.datasetSizeWarning, 'afterSave', true)
          this.$set(this.datasetSizeWarning, 'datasetSize', null)
          this.$set(this.datasetSizeWarning, 'maxDataSize', null)
          this.$set(this.datasetSizeWarning, 'showPopup', true)
          this.$set(this.datasetSizeWarning, 'totalDataSize', null)
        }
      } finally {
        this.submitSave = false
        this.loadingPage = false
        this.closePopup('saveDataset')
      }
    },
    async savePreprocessing({ name, description }) {
      this.submitSave = true
      this.loadingPage = true
      try {
        await this.saveEDARecipe({
          dataId: this.mainData.datasetId,
          version: this.preProcHeadVersion,
          name: name,
          description: description,
          projectId: this.projectId
        })
      } finally {
        await this.loadProjectDetail(this.projectId)

        this.submitSave = false
        this.loadingPage = false
      }
      this.closePopup('savePreprocessing')
    },
    selectPreprocessing(preprocessingId) {
      this.mainData.preprocessingId = preprocessingId
      this.mainData.isUsed = false
    },
    showDetail(e) {
      this.getDetailPageItems(e, 'detail')
      this.showData.showContent = 'column'
      this.columnData.activeTab = 0
      this.columnData.visibleContents = 'histogram'
      this.showData.showColumn = this.data.body.pageBody.dataset.tableColumns[e]
    },
    showStatisticsDetail(e) {
      this.getDetailPageItems(e, 'statistics')
      this.showData.showContent = 'column'
      this.columnData.activeTab = 0
      this.columnData.visibleContents = 'histogram'
      this.showData.showColumn =
        this.data.body.pageBody.statistics.tableColumns[e]
    },
    // 前処理適用のメイン画面遷移
    backMain() {
      this.showData.showContent = 'main'
      this.showData.showColumn = {}
      this.pageBackMainState()
    },
    closeErrorPopup() {
      this.mainData.errorPopup.isAutoProcess = false
      this.mainData.errorPopup.isShow = false
      this.mainData.errorPopup.text = ''
    },

    // 前処理適用メソッド
    async applyPreprocessing() {
      this.loadingPage = true
      const EDARecipeAccountId =
        this.preprocessingList[this.mainData.preprocessingId].accountId
      const EDARecipeId = this.mainData.preprocessingId
      try {
        await this.applyEDARecipe({
          EDARecipeAccountId,
          EDARecipeId,
          version: this.preProcHeadVersion
        })
        this.mainData.isUsed = true
      } catch (e) {
        const errorName = this.$t(`preprocessing.error.${e.name}`)
          ? e.name
          : 'UNKNOWN_ERROR'
        const text = this.$t(
          `preprocessing.error.${errorName}`,
          e === null
            ? {}
            : {
                ...e,
                preprocErrorBlockName:
                  e.preprocErrorBlockName &&
                  this.$t(
                    `preprocessing.layerNames.${e.preprocErrorBlockName}`
                  ),
                preprocErrorBlockInputColumns:
                  e.preprocErrorBlockInputColumns &&
                  e.preprocErrorBlockInputColumns.map((x) => `'${x}'`).join()
              }
        )
        this.mainData.errorPopup.isAutoProcess = false
        this.mainData.errorPopup.isShow = true
        this.mainData.errorPopup.text = text
      } finally {
        await this.updateInfo(false)
      }
    },
    async backVersion() {
      this.loadingPage = true
      this.moveVersion(-1)
      await this.updateInfo()
    },
    async nextVersion() {
      this.loadingPage = true
      this.moveVersion(1)
      await this.updateInfo()
    },
    async resetVersion() {
      this.submitReset = true
      this.loadingPage = true
      try {
        await this.resetPreProcessing()
        await this.updateInfo()
      } finally {
        this.submitReset = false
        this.loadingPage = false
      }
      this.closePopup('resetPreprocessing')
      this.mainData.isUsed = false
    },
    async applyOneHot() {
      this.loadingPage = true
      await this.replaceRowValuesOneHot({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
    },
    async applyDummy() {
      this.loadingPage = true
      await this.replaceRowValuesDummy({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
    },
    async applyLabel(encoding) {
      this.loadingPage = true
      await this.replaceRowValues({
        colId: this.showData.showColumn.id,
        rowValues: encoding.key,
        rowReplacingValues: encoding.value,
        fillingValue: encoding.filling
      })
      await this.updateInfo()
    },
    async changeNull(num) {
      this.loadingPage = true
      await this.fillNullValue({
        colId: this.showData.showColumn.id,
        fillingValue: Number(num)
      })
      await this.updateInfo()
    },
    async dropNull() {
      this.loadingPage = true
      await this.dropNullValue({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
    },
    async dropColumn() {
      this.loadingPage = true
      await this.deleteColumn({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
    },
    async clickApplyStandardize() {
      this.loadingPage = true
      await this.applyStandardize({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
    },
    async clickApplyYeoJohnson() {
      this.loadingPage = true
      await this.applyYeoJohnson({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
    },
    async clickApplyBinning({ binningBorders }) {
      this.loadingPage = true
      await this.applyBinning({
        colId: this.showData.showColumn.id,
        binningBorders
      })
      await this.updateInfo()
    },
    async clickDeleteRows(settings) {
      this.loadingPage = true
      await this.applyDeleteRows(settings)
      await this.updateInfo()
    },
    dropOutlier: async function () {
      this.loadingPage = true

      await this.deleteOutlier({
        colId: this.showData.showColumn.id
      })
      await this.updateInfo()
      if (
        this.boxPlotData != null &&
        Object.keys(this.boxPlotData).length > 0
      ) {
        await this.getSelectedLearningDataBoxPlot({
          columnName: this.showData.showColumn.name
        })
      }
    },
    goDatasetListPage: function () {
      this.$router.push({
        name: 'datasetList'
      })
    },
    goDetail() {
      let name = ''
      let params = {}
      if (this.projectId) {
        name = 'datasetProjectDetail'
        params = {
          projectId: this.projectId,
          id: this.mainData.datasetId
        }
      } else {
        name = 'datasetDetail'
        params = {
          preprocessingId: this.mainData.preprocessingId,
          id: this.mainData.datasetId
        }
      }
      this.$router.push({
        name: name,
        params: params
      })
    },
    async backColumnSelect() {
      // preventLeaveの強制解除
      this.nextPageFlag = true
      this.correctTransition = true
      await this.resetPreProcessing()
      this.$router.push({
        name: 'selectTargetColumn',
        params: {
          projectId: this.projectId,
          datasetId: this.mainData.datasetId
        }
      })
    },
    // 前処理実行後の更新処理
    async updateInfo(closeError = true) {
      const beforeColName = this.showData.showColumn?.name

      // 前処理ブロックの更新
      await this.fetchDatasetDetail({
        dataId: this.mainData.datasetId,
        accountId: this.datasetList[this.mainData.datasetId].accountId,
        includeGraph: false,
        includeData: false
      })
      await this.fetchSelectedLearningDataForAnalysis({
        type: 'structured',
        id: this.mainData.datasetId,
        ...this.checkAndSetDataReq()
      })

      // 列詳細画面を表示している場合の表示対象更新
      if (this.showData.showContent === 'column') {
        const newIndex = this.columns.findIndex(
          (item) => item?.name === beforeColName
        )
        if (newIndex >= 0) {
          // 同一名の列があればそれを表示
          this.showData.showColumn = this.columns[newIndex]
        } else {
          // 同一名の列がなければメインに戻る
          this.backMain()
        }
      }

      // 前処理適用時のエラーポップアップを閉じる
      if (closeError) {
        this.closeErrorPopup()
      }

      this.loadingPage = false
    },
    notPrevent() {
      this.data.isPrevent = false
      this.correctTransition = true
    },
    restartPrevent() {
      this.correctTransition = false
    },
    checkPrevent(nextPageName) {
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage(
          'preprocessing',
          nextPageName,
          this.progressTraining?.laterSelectTargetColumn
        ) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeaveOtherTrainPages.closePopup()
    },
    pageBack() {
      let toPage = null
      if (this.progressTraining.laterSelectTargetColumn) {
        toPage = {
          name: 'datasetSetting',
          params: {
            projectId: this.projectId
          }
        }
      } else {
        toPage = {
          name: 'selectTargetColumn',
          params: {
            projectId: this.projectId,
            datasetId: this.progressTraining.datasetId
          }
        }
      }
      this.$router.push(toPage)
    },
    async getDetailPageItems(columnIndex, type) {
      if (this.mainData.settedAll) return
      this.mainData.loadingDetailPage = true
      await this.getLearningDataForAnalysis()
      if (type === 'statistics') {
        this.showData.showColumn =
          this.data.body.pageBody.statistics.tableColumns[columnIndex]
      } else {
        this.showData.showColumn =
          this.data.body.pageBody.dataset.tableColumns[columnIndex]
      }
      this.mainData.loadingDetailPage = false
    },
    async getLearningDataForAnalysis() {
      this.mainData.loadingAll = true
      this.mainData.settedAll = true
      await this.fetchSelectedLearningDataForAnalysis({
        type: 'structured',
        id: this.mainData.datasetId,
        withGraph: true,
        withDescribe: true,
        withOutlier: true,
        noLoading: true
      })
      this.mainData.loadingAll = false
    },
    checkAndSetDataReq() {
      let req = {}
      if (this.mainData.settedAll) {
        req = {
          withGraph: true,
          withDescribe: true,
          withOutlier: true
        }
      }
      return req
    }
  },
  watch: {
    datasetSizeWarning: {
      deep: true,
      handler: function (newVal) {
        if (newVal.showPopup === true) {
          this.showPopup('datasetSizeExceedCaution')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  display: flex;
  flex-direction: row;
}
.text {
  white-space: pre-line;
}
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
