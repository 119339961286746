<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-components -->
<template>
  <loading v-if="running" />
  <div v-else class="c-wrap">
    <sidebar-project-detail v-if="!notFoundProject || loading" />
    <div class="c-body-wrap">
      <div class="dataset-select">
        <div class="dataset-1">
          <texts> データセット1 </texts>
          <selectBox
            v-model="leftDataId"
            class="select-box"
            :items="datasetOptions"
          />
        </div>
        <div class="dataset-2">
          <texts> データセット2 </texts>
          <selectBox
            v-model="rightDataId"
            class="select-box"
            :items="datasetOptions"
          />
        </div>
      </div>
      <div class="concat-select">
        <div class="concat-type">
          <texts> 処理方法 </texts>
          <selectBox
            v-model="concatType"
            class="select-box"
            :items="concatTypeOptions"
          />
        </div>
        <div v-if="concatType == 'column'" class="concat-detail">
          <div class="concat-key-left">
            <texts> 左キー </texts>
            <selectBox
              v-model="joinInfo.leftKey"
              class="select-box"
              :items="leftColumnOptions"
            />
          </div>
          <div class="concat-key-right">
            <texts> 右キー </texts>
            <selectBox
              v-model="joinInfo.rightKey"
              class="select-box"
              :items="rightColumnOptions"
            />
          </div>
          <div class="concat-key-join-type">
            <texts> 結合方式 </texts>
            <selectBox
              v-model="joinInfo.joinType"
              class="select-box"
              :items="joinTypeOptions"
            />
          </div>
          <div class="concat-key-conflict">
            <texts> 列名重複時の処理 </texts>
            <selectBox
              v-model="joinInfo.onConflict"
              class="select-box"
              :items="conflictTypeOptions"
            />
          </div>
        </div>
      </div>
      <button-main
        text="実行"
        type="emphasis"
        :isDisabled="!testOk"
        @click="run"
      />
      <loadingIcon v-if="testRunning" />
      <texts :text="testError" color="caution" />
    </div>
  </div>
</template>

<script>
import { splitFullId } from '@/lib/misc.js'
import { mapActions, mapGetters } from 'vuex'
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import axios from 'axios'
import ButtonMain from '../../components/atoms/button-main.vue'
import selectBox from '@/components/molecules/select-box'
import loading from '@/components/atoms/loading.vue'
import loadingIcon from '@/components/atoms/loading-icon.vue'

export default {
  name: 'PageDatasetCombine',
  components: {
    ButtonMain,
    selectBox,
    sidebarProjectDetail,
    loading,
    loadingIcon
  },
  data() {
    return {
      leftDataId: this.$route?.params?.datasetId,
      rightDataId: this.$route?.params?.datasetId,
      concatType: 'row',
      joinInfo: {
        leftKey: null,
        rightKey: null,
        joinType: 'inner',
        onConflict: 'keep'
      },
      testOk: false,
      testRunning: false,
      testError: '',
      running: false
    }
  },
  async mounted() {
    await this.load()
    await this.test()
  },
  computed: {
    ...mapGetters('project', ['projectList']),
    ...mapGetters('datasets', ['datasetList', 'datasetLoading']),
    projectId() {
      return this.$route.params.projectId ?? this.$route.params.id
    },
    datasetOptions() {
      return Object.values(this.datasetList).map((x) => ({
        name: x.name,
        value: x.id
      }))
    },
    concatTypeOptions() {
      return [
        {
          name: '行を増やす',
          value: 'row'
        },
        {
          name: '列を増やす',
          value: 'column'
        }
      ]
    },
    joinTypeOptions() {
      return [
        {
          name: '共通結合 (INNER JOIN)',
          value: 'inner'
        },
        {
          name: '左優先結合 (LEFT JOIN)',
          value: 'left'
        },
        {
          name: '右優先結合 (RIGHT JOIN)',
          value: 'right'
        }
      ]
    },
    conflictTypeOptions() {
      return [
        {
          name: 'データセット1を残す',
          value: 'keep'
        },
        {
          name: 'データセット2の値を使用',
          value: 'preferRight'
        },
        {
          name: '優先値 (COALESCE)',
          value: 'coalesce'
        }
      ]
    },
    leftColumnOptions() {
      return this.leftDataId == null
        ? []
        : this.datasetList[this.leftDataId].columns.map((x) => ({
            name: x,
            value: x
          }))
    },
    rightColumnOptions() {
      return this.rightDataId == null
        ? []
        : this.datasetList[this.rightDataId].columns.map((x) => ({
            name: x,
            value: x
          }))
    },
    rowModeError() {
      return (
        new Set(this.datasetList[this.leftDataId]) !==
        new Set(this.datasetList[this.rightDataId])
      )
    }
  },
  methods: {
    ...mapActions('datasets', ['loadDatasetList']),
    ...mapActions('project', ['loadProjectDetail']),
    async load() {
      this.loadDatasetList(this.projectId)
    },
    async test() {
      try {
        this.testRunning = true
        this.testError = ''
        if (this.concatType === 'row') {
          await axios.post('/api/mf/concat/dry-run', {
            leftDataId: this.leftDataId,
            rightDataId: this.rightDataId,
            concatType: this.concatType,
            joinInfo: null
          })
        } else {
          await axios.post('/api/mf/concat/dry-run', {
            leftDataId: this.leftDataId,
            rightDataId: this.rightDataId,
            concatType: this.concatType,
            joinInfo: this.joinInfo
          })
        }
        this.testOk = true
      } catch (e) {
        this.testOk = false
        const message = e.response.data.detail.message
        this.testError = this.$t('datasetCombine.errors.' + message)
      } finally {
        this.testRunning = false
      }
    },

    async run() {
      let result
      this.running = true
      if (this.concatType === 'row') {
        result = await axios.post('/api/mf/concat/run', {
          leftDataId: this.leftDataId,
          rightDataId: this.rightDataId,
          concatType: this.concatType,
          joinInfo: null
        })
      } else {
        result = await axios.post('/api/mf/concat/run', {
          leftDataId: this.leftDataId,
          rightDataId: this.rightDataId,
          concatType: this.concatType,
          joinInfo: this.joinInfo
        })
      }
      const createdFullId = result.data.created_dataset
      const createdId = splitFullId(createdFullId)[0]
      for (let retryCount = 0; retryCount < 5; retryCount++) {
        await this.loadProjectDetail(this.$route.params.projectId)
        if (
          this.projectList?.[this.projectId]?.listData?.some(
            (item) => item.id === createdId
          )
        ) {
          break
        }
        // データができてなかったら待つ
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }

      this.$router.push({
        name: 'datasetProjectList',
        params: {
          projectid: this.$route.params.projectId
        }
      })
      location.reload()
    },

    loading() {
      return this.detailLoading || this.loadingProjectList
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    }
  },
  watch: {
    leftDataId() {
      if (this.concatType === 'column') {
        this.joinInfo.leftKey = this.datasetList[this.leftDataId].columns?.[0]
      }
      this.test()
    },
    rightDataId() {
      if (this.concatType === 'column') {
        this.joinInfo.rightKey = this.datasetList[this.rightDataId].columns?.[0]
      }
      this.test()
    },
    concatType(newVal) {
      if (newVal === 'column') {
        this.joinInfo.leftKey = this.datasetList[this.leftDataId].columns?.[0]
        this.joinInfo.rightKey = this.datasetList[this.rightDataId].columns?.[0]
      }
      this.test()
    }
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}

.select-box {
  width: 80%;
  height: adjustVW(48);
  margin: $space-base;
}

.dataset-select {
  display: flex;
  height: 20%;
  margin: $space-base;
  .dataset-1 {
    flex-grow: 1;
    margin: $space-base;
    background: $background;
  }
  .dataset-2 {
    flex-grow: 1;
    margin: $space-base;
    background: $background;
  }
}
.concat-select {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: $space-base;
  background: $background;
  .concat-type {
    margin: $space-base;
    background: $background;
  }
  .concat-detail {
    margin: $space-base;
    background: $background;
  }
}
</style>
