<template>
  <div class="apply-body-wrap">
    <SelectBox
      :value="column_name"
      class="column-name-input"
      isGray
      :items="columnsOption"
      @input="setCondition('column_name', $event)"
    />
    <SelectBox
      :value="compare_type"
      class="compare-type-input"
      isGray
      :items="compareTypes"
      @input="setCondition('compare_type', $event)"
    />
    <InputBox
      :value="compare_value"
      class="compare-value-input"
      isGray
      @input="setCondition('compare_value', $event)"
    />
    <TextWithIcons
      class="delete-button"
      :text="$t('preprocessing.removeCondition')"
      iconName="delete"
      size="small"
      color="emphasis"
      @click.native="removeCondition"
    />
  </div>
</template>

<script>
import TextWithIcons from '@/components/molecules/text-with-icon'
import InputBox from '@/components/molecules/input-box'
import SelectBox from '@/components/molecules/select-box'

export default {
  components: {
    InputBox,
    TextWithIcons,
    SelectBox
  },
  data() {
    return {}
  },
  props: {
    columns: Array,

    column_name: String,
    compare_type: String,
    compare_value: {}
  },
  methods: {
    setCondition(type, value) {
      this.$emit('set-condition', {
        type,
        value
      })
    },
    removeCondition() {
      this.$emit('remove-condition')
    }
  },
  computed: {
    compareTypes() {
      return [
        {
          id: 0,
          name: '以上',
          value: 'greater_equal'
        },
        {
          id: 1,
          name: '以下',
          value: 'less_equal'
        },
        {
          id: 2,
          name: '等しい',
          value: 'equal'
        }
      ]
    },
    columnsOption() {
      return this.columns.map((x, i) => ({
        id: i,
        name: x,
        value: x
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-body-wrap {
  display: flex;
  margin: $space-base;

  .column-name-input {
    margin: $space-base;
  }
  .compare-type-input {
    width: 20%;
    margin: $space-base;
  }
  .compare-value-input {
    width: 40%;
    margin: $space-base;
  }
  .delete-button {
    margin: $space-base;
  }
}
</style>
