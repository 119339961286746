var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!['Input', 'SqlInput'].includes(_vm.layer.type))?_c('div',{ref:'block' + _vm.layer.id,staticClass:"block",class:{
    'block-active': _vm.activeBlock === _vm.layer.id,
    'block-scroll-x': _vm.useScrollX
  },on:{"click":function($event){$event.stopPropagation();_vm.$emit('block-click', _vm.layer.id), _vm.checkPos(_vm.layer.id)}}},[_c('div',{staticClass:"type"},[_c('div',{staticClass:"icon"},[_c('icons',{attrs:{"iconName":_vm.layerIconName}})],1),_c('texts',{attrs:{"text":_vm.$t('preprocessing.layerNames.' + _vm.layer.type),"isBold":""}})],1),(!_vm.layer.type.endsWith('DeleteRows'))?_c('div',{staticClass:"content"},[_c('texts',{attrs:{"text":_vm.$t('preprocessing.chart.colum') + _vm.layer.column,"size":"small"}})],1):_vm._e(),_c('div',{ref:'popup' + _vm.layer.id,staticClass:"popup-scroll-wrapper",style:({ '--scrollX': _vm.scrollX + 'px', '--pos': _vm.popupPos + 'px' })},[_c('popup-balloon',{staticClass:"popup-wrap",attrs:{"isShow":_vm.activeBlock === _vm.layer.id,"showLeft":_vm.showLeft},on:{"close":function($event){return _vm.$emit('popup-close')}}},[_c('div',{staticClass:"popup-inner"},[(!_vm.layer.type.endsWith('DeleteRows'))?_c('div',{staticClass:"popup-item"},[_c('text-with-title',{attrs:{"title":_vm.$t('preprocessing.chart.balloon.colum'),"text":_vm.layer.column}})],1):_vm._e(),(_vm.layer.type.endsWith('DeleteRows'))?_c('div',{staticClass:"popup-item"},[_c('text-with-title',{attrs:{"title":_vm.$t('preprocessing.chart.balloon.condition'),"text":_vm.deleteRowDescription(_vm.layer)}})],1):_vm._e(),_c('div',{staticClass:"popup-item"},[_c('text-with-title',{attrs:{"title":_vm.$t('preprocessing.chart.balloon.type'),"text":_vm.$t('preprocessing.layerNames.' + _vm.layer.type)}})],1),(
            [
              'Replace',
              'SqlReplace',
              'ReplaceDataNormalizer',
              'SqlReplaceDataNormalizer',
              'SqlReplaceNumericConverter',
              'ReplaceNumericConverter'
            ].includes(_vm.layer.type)
          )?_c('div',{staticClass:"popup-item"},[_c('div',{staticClass:"popup-title"},[_c('texts',{attrs:{"text":_vm.$t('preprocessing.chart.balloon.conversionTable'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"popup-value"},_vm._l((_vm.layer.old_values),function(oldValue,index){return _c('div',{key:index,staticClass:"popup-value-inner"},[_c('div',{staticClass:"popup-value-item"},[_c('texts',{attrs:{"text":oldValue,"size":"small"}})],1),_c('div',{staticClass:"popup-value-icon"},[_c('icons',{attrs:{"iconName":"change","size":"min"}})],1),_c('div',{staticClass:"popup-value-item"},[_c('texts',{attrs:{"text":_vm.layer.new_values[index],"size":"small"}})],1)])}),0)]):(
            [
              'ReplaceOneHot',
              'SqlReplaceOneHot',
              'ReplaceDummy',
              'SqlReplaceDummy'
            ].includes(_vm.layer.type)
          )?_c('div',{staticClass:"popup-item"},[_c('div',{staticClass:"popup-title"},[_c('texts',{attrs:{"text":_vm.$t('preprocessing.chart.balloon.conversionTarget'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"popup-category"},_vm._l((_vm.layer.categories),function(category,index){return _c('texts',{key:index,staticClass:"popup-category-inner",attrs:{"text":category,"size":"small"}})}),1)]):_vm._e()])])],1)]):_c('div',{staticClass:"block-type-input",class:{ 'block-active': _vm.activeBlock === _vm.layer.id },on:{"click":function($event){return _vm.$emit('block-click', _vm.layer.id)}}},[_c('div',{staticClass:"type"},[_c('div',{staticClass:"icon"},[_c('icons',{attrs:{"iconName":_vm.layerIconName}})],1),_c('texts',{attrs:{"text":_vm.$t('preprocessing.layerNames.' + _vm.layer.type),"isBold":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }