<template>
  <div class="apply-body-wrap">
    <div class="connector-wrap">
      <texts :text="$t('preprocessing.connectorTitle')" />
      <SelectBox
        :value="settings.connector"
        class="connector-input"
        isGray
        :items="connectorTypes"
        @input="setConnector($event)"
      />
    </div>
    <texts :text="$t('preprocessing.conditions')" />
    <Condition
      v-for="(item, index) in settings.conditions"
      :key="index"
      :columns="columns"
      :column_name="item.column_name"
      :compare_type="item.compare_type"
      :compare_value="item.compare_value"
      @set-condition="setCondition(index, $event)"
      @remove-condition="removeCondition(index)"
    />
    <div class="foot">
      <TextWithIcons
        class="generate-button"
        iconName="plus"
        :text="$t('preprocessing.addCondition')"
        size="small"
        color="emphasis"
        @click.native="addCondition"
      />
      <ButtonMain
        class="exec-button"
        :text="$t('preprocessing.doDeleteRows')"
        type="emphasis"
        @click="doDeleteRows"
      />
    </div>
  </div>
</template>

<script>
import TextWithIcons from '@/components/molecules/text-with-icon'
import SelectBox from '@/components/molecules/select-box'
import ButtonMain from '@/components/atoms/button-main'
import Condition from './condition'

export default {
  components: {
    ButtonMain,
    Condition,
    TextWithIcons,
    SelectBox
  },
  data() {
    return {}
  },
  props: {
    columns: Array,

    settings: Object
  },
  methods: {
    addCondition() {
      this.$emit('input', {
        connector: this.settings.connector,
        conditions: [
          ...this.settings.conditions,
          {
            column_name: this.columns[0],
            compare_type: 'equal',
            compare_value: 0
          }
        ]
      })
    },
    setCondition(index, { type, value }) {
      const conditions = [...this.settings.conditions]
      conditions[index][type] = value
      this.$emit('input', {
        connector: this.settings.connector,
        conditions
      })
    },
    removeCondition(index) {
      const conditions = [...this.settings.conditions]
      conditions.splice(index, 1)
      this.$emit('input', {
        connector: this.settings.connector,
        conditions
      })
    },
    setConnector(value) {
      this.$emit('input', {
        connector: value,
        conditions: this.settings.conditions
      })
    },
    doDeleteRows() {
      this.$emit('do-delete-rows')
    }
  },
  computed: {
    connectorTypes() {
      return [
        {
          id: 0,
          name: 'かつ',
          value: 'and'
        },
        {
          id: 1,
          name: 'または',
          value: 'or'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-body-wrap {
  .prompt {
    width: 80%;
    height: 30%;
    resize: none;
  }
}

.generate-button {
  margin: $space-base;
}

.connector-wrap {
  .connector-input {
    width: 30%;
    margin: $space-base;
  }
}
</style>
